import React from 'react';
import ReactDOM from 'react-dom/client';
import GTM from 'react-gtm-module';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Initialize GTM
const tagManagerArgs = {
  gtmId: 'GTM-5ZT5J9TD'  // Replace with your GTM ID
};
GTM.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <script type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
    <App />
  </React.StrictMode>
);
// Performance reporting (optional, based on environment)
/* if (process.env.NODE_ENV === 'production') {
  import('./reportWebVitals').then(({ default: reportWebVitals }) => {
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  });
} */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
