import React, { useContext } from "react";
import logo from "../logo.svg";
import logoMobile from "../logo-rodape.svg";
import { AuthContext } from "../providers/AuthProvider";
import { useNavigate } from "react-router-dom";

function Header({ onFilterChange, searchComponent, filterComponent }) {
  const { logOut, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const logoutAndNavigate = async () => {
    await logOut();
    navigate("/");
  };

  return (
    <header>
      <div className="w-full sm:w-8/12 mx-auto px-4 py-8 sm:px-0 items-stretch hidden sm:flex">
        <div>
          <a href="https://vitrinepublica.com.br">
            <img src={logo} alt="Logo Vitrine Pública" />
          </a>
        </div>
        <div className="ms-auto flex items-stretch">
          <div className="px-8 border-r-2 border-cinza-claro flex items-center">
            <a
              className="text-cinza-escuro text-center sm:text-xl"
              href="https://vitrinepublica.com.br"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
              </svg>
            </a>
          </div>
          <div className="px-8 border-r-2 border-cinza-claro flex items-center">
            <a
              className="text-cinza-escuro text-center sm:text-xl"
              href="https://consulta.vitrinepublica.com.br"
            >
              Atas de Registro de Preços
            </a>
          </div>
          <div className="px-8 border-r-2 border-cinza-claro flex items-center">
            <a
              className="text-cinza-escuro text-center sm:text-xl"
              href="https://vitrinepublica.com.br/quem-somos"
            >
              Quem Somos
            </a>
          </div>
          <div className="px-8 flex items-center">
            <a
              className="text-cinza-escuro text-center sm:text-xl"
              href="https://vitrinepublica.com.br/fale-conosco"
            >
              Fale Conosco
            </a>
          </div>
          {isAuthenticated && (
            <div className="px-8 flex items-center">
              <button
                className="text-cinza-escuro text-center sm:text-xl"
                onClick={() => logoutAndNavigate()}
              >
                Sair
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="w-full sm:w-8/12 mx-auto px-4 py-8 sm:px-0 flex items-stretch block sm:hidden">
        <div>
          <a href="https://vitrinepublica.com.br">
            <img src={logo} alt="Logo Vitrine Pública" />
          </a>
        </div>
        <div className="ms-auto flex items-stretch">
          <button
            id="mobileMenuBtn"
            className="sm:hidden text-cinza-escuro text-xl"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-12 h-12 text-cinza-claro"
            >
              <path d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>
      </div>
      <div id="mobileMenu" className="fixed inset-0 bg-roxo px-4 hidden">
        <div className="flex flex-col h-full pt-4 items-center">
          <div className="w-full flex items-center">
            <a href="<?php echo home_url() ?>">
              <img
                src={logoMobile}
                className="w-auto h-12"
                alt="Logo Vitrine Pública"
              />
            </a>
            <div className="grow text-right">
              <button id="closeMobileMenu" className="text-white text-2xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-12 h-12 text-white"
                >
                  <path d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
          <nav className="mt-12 w-full">
            <a
              className="text-white block py-2 border-b-verde border-b text-xl text-center"
              href="https://vitrinepublica.com.br"
            >
              Início
            </a>
            <a
              className="text-white block py-2 border-b-verde border-b text-xl text-center"
              href="https://consulta.vitrinepublica.com.br"
            >
              Atas de Registro de Preços
            </a>
            <a
              className="text-white block py-2 border-b-verde border-b text-xl text-center"
              href="https://vitrinepublica.com.br/quem-somos"
            >
              Quem Somos
            </a>
            <a
              className="text-white block py-2 border-b-verde border-b text-xl text-center"
              href="https://vitrinepublica.com.br/fale-conosco"
            >
              Fale Conosco
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
