import React from "react";
import logoRodape from "../logo-rodape.svg";

function Footer() {
  return (
    <footer id="rodape" className="bg-roxo">
      <div className="w-full sm:w-8/12 mx-auto px-4 sm:px-0 py-20">
        <div className="grid grid-cols-1 sm:grid-cols-3 justify-center mb-12">
          <div className="border-0 border-white sm:border-r py-12 sm:pe-12 sm:me-12 text-center">
            <img
              className="max-w-full block mx-auto"
              src={logoRodape}
              alt="Logo Vitrine Pública"
            />
            <p className="text-white">contato@vitrinepublica.com.br</p>
          </div>
          <div className="flex items-center">
            <div className="w-full sm:w-auto">
              <a
                className="block text-white mb-4"
                href="https://vitrinepublica.com.br"
              >
                Início
              </a>
              <a
                className="block text-white mb-4"
                href="https://consulta.vitrinepublica.com.br"
              >
                Atas de Registro de Preços
              </a>
              <a
                className="block text-white mb-4"
                href="https://vitrinepublica.com.br/quem-somos"
              >
                Quem Somos
              </a>
              <a
                className="block text-white mb-4"
                href="https://vitrinepublica.com.br/fale-conosco"
              >
                Fale conosco
              </a>
            </div>
          </div>
          <div>
            <h3 className="text-white font-bold">Newsletter</h3>
            <div className="form" id="wpforms-13">
              <form
                id="wpforms-form-13"
                className="w-full"
                data-formid="13"
                method="post"
                encType="multipart/form-data"
                action="/"
                data-token="952feabd4d48c3a6ec64dc87eb10c4aa"
                noValidate
              >
                <div className="wpforms-field-container mb-2">
                  <div
                    id="wpforms-13-field_1-container"
                    className="flex flex-col"
                    data-field-id="1"
                  >
                    <label className="text-white" htmlFor="wpforms-13-field_1">
                      E-mail{" "}
                      <span
                        className="wpforms-required-label"
                        aria-hidden="true"
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="email"
                      id="wpforms-13-field_1"
                      className="w-full rounded p-2"
                      name="wpforms[fields][1]"
                      spellCheck="false"
                      placeholder="Cadastre seu e-mail e fique por dentro de todas as novidades, incluindo novas atas, itens em destaque e muito mais!"
                      ariaErrorMessage="wpforms-13-field_1-error"
                      required
                    />
                  </div>
                </div>
                <div className="wpforms-submit-container">
                  <button
                    type="submit"
                    name="wpforms[submit]"
                    id="wpforms-submit-13"
                    className="rounded wpforms-submit bg-verde text-white py-2 px-4"
                    data-alt-text="Enviando…"
                    data-submit-text="Enviar"
                    ariaLive="assertive"
                    value="wpforms-submit"
                  >
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div>
          <p className="text-center text-white">
            © 2023 Vitrine Pública | Todos os direitos reservados
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
