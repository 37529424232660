import React, { lazy, Suspense, useState, useContext, useEffect } from "react";
import {
  useLocation,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import "./output.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AuthProvider, { AuthContext } from "./providers/AuthProvider";

const Atas = lazy(() => import("./components/Atas"));
const AtaDetail = lazy(() => import("./components/AtaDetail"));
const Login = lazy(() => import("./components/Login"));

// New component for GTM tracking
const GTMTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "pageview",
      pagePath: location.pathname,
    });
  }, [location]);

  return null;
};

function App() {
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("default");

  return (
    <AuthProvider>
      <Router>
        <GTMTracking /> {/* Include the new GTMTracking component */}
        <Header />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/login" element={<LoginWrapper />} />
            <Route path="/atas/:id" element={<AtaDetail />} />
            <Route
              path="/atas"
              element={
                <Atas
                  setFilters={setFilters}
                  setSearchTerm={setSearchTerm}
                  setSortBy={setSortBy}
                  filters={filters}
                  searchTerm={searchTerm}
                  sortBy={sortBy}
                />
              }
            />
            <Route
              path="/"
              element={
                <Atas
                  setFilters={setFilters}
                  setSearchTerm={setSearchTerm}
                  setSortBy={setSortBy}
                  filters={filters}
                  searchTerm={searchTerm}
                  sortBy={sortBy}
                />
              }
            />
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

function LoginWrapper() {
  const { logIn } = useContext(AuthContext);
  return <Login logIn={logIn} />;
}

export default App;
