import React, { useState, useEffect, createContext, useCallback } from "react";
import axios from "axios";

export const AuthContext = createContext();

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectedProductID, setSelectedProductID] = useState(0);

  const checkAuthentication = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/check-login`,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
          withCredentials: true,
        }
      );
      const isAuth = response.data.user ? true : false;
      sessionStorage.setItem("isAuthenticated", isAuth.toString());
      setIsAuthenticated(isAuth);
    } catch (error) {
      sessionStorage.removeItem("isAuthenticated");
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    const storedAuthState = sessionStorage.getItem("isAuthenticated");
    if (storedAuthState === "true") {
      setIsAuthenticated(true);
      checkAuthentication(); // Only check if the stored state is true
    } else {
      setIsAuthenticated(false);
    }
  }, [checkAuthentication]);

  const logIn = () => {
    sessionStorage.setItem("isAuthenticated", "true");
    setIsAuthenticated(true);
  };

  const logOut = async () => {
    return new Promise((resolve) => {
      sessionStorage.removeItem("isAuthenticated");
      setIsAuthenticated(false);
      resolve();
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        logIn,
        logOut,
        setSelectedProductID,
        selectedProductID,
        checkAuthentication,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
